.register-page {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 100px;

  .form-wrapper {
    max-width: 400px;
    background: white;
    padding: 12px 24px;
    border-radius: 12px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.05), 0 15px 12px rgba(0, 0, 0, 0.06);

    .LogoWrapper {
      width: 100%;
      padding: 24px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      .Logo {
        width: 100%;
        height: 80px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("./../../images/jp-logo-white.png");
      }
    }

    .register-form {
      padding: 0 12px;
      .register-form-button {
        width: 100%;
      }

      .ant-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;

        .ant-form-item-label {
          width: 100%;
          align-self: flex-start;
          text-align: left;
          line-height: 22px;
        }
        .ant-form-item-control-wrapper {
          width: 100%;
        }

        input[placeholder="First Name"] {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        input[placeholder="Last Name"] {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .ant-form-item-required::before {
          display: none;
        }
        .ant-form-item-required::after,
        label[title="Name"]::after {
          display: inline-block;
          margin-right: 4px;
          color: #f5222d;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: "*";
        }
      }
    }

    .ant-alert-error{
      margin-bottom: 16px;
    }

  } 
}

.RegisterPage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px;

  .RegisterPage__Content {
    width: 100%;
    max-width: 768px;
    display: flex;
    align-items: center;
    padding: 12px;

    .RegisterPage__Form {
      margin: 0 auto;
      width: 100%;
      position: relative;

      .profilePicContainer {
        display: flex;
        justify-content: center;
        padding-bottom: 50px;
      }
      .avatar-uploader {
        width: 256px;
        height: 256px;
        position: relative;
        .cameraButton {
          position: absolute;
          bottom: 12px;
          right: 12px;
          cursor: pointer;
        }
        .uploadButton {
          position: absolute;
          bottom: 12px;
          right: 50px;
          cursor: pointer;
        }
        img {
          border-radius: 6px;
        }
        .anticon-user {
          font-size: 100px;
          border: 1px solid #cccccc;
          line-height: 256px;
          width: 256px;
          border-radius: 6px;
          background: #f1f1f1;
        }
      }
      .ant-form-item-label {
        line-height: 28px;
      }

      .ant-form-item {
        margin-bottom: 10px;
      }

      .avatar-uploader {
        margin-bottom: 16px;
        width: 256px;
        height: 256px;
      }

      .ant-calendar-picker {
        width: 100%;
      }

      .ant-upload {
        margin: 0 auto;
        float: none;
      }
      .regbutton {
        display: flex;
        justify-content: flex-end;
        padding-top: 12px;
      }
    }
  }
}

@import "../_media-queries/_register";
