.centerAll {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    position: fixed;
    transition: "all 0.2s ease-in";
    left: 0;
    z-index: 5;
    top: 0;
    padding-left: 250px;
    pointer-events: none;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .ant-spin-dot-item {
        background: #1890ff;
    }
    .ant-spin-text {
        padding-top: 24px;
    }
}

.ant-popover-inner-content {
    max-height: 220px;
    width: 220px;
    overflow: auto;

    .attendanceLogPopover__table {
        display: table;
        width: 100%;
        .attendanceLogPopover__table-row {
            display: table-row;
            .attendanceLogPopover__table-cell {
                display: table-cell;
                &.attendanceLogPopover__table-id {
                    font-weight: bold;
                }
                &.attendanceLogPopover__table-details {
                    text-align: right;
                }
            }
        }
    }
}

.loading-indicator {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 2;
    overflow: hidden;
    background: #f5f8fa;
    opacity: 0.6;
    height: 100%;
}

