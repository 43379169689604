.camera__modal {
  .webcam--wrapper {
    position: relative;
    .ant-spin {
      position: absolute;
      left: 50%;
      margin-left: -16px;
      top: 225px;
      z-index: 1;
    }
  }

  .image-cropper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }
}
