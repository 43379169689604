html {
  font-size: $base-font-size;
  height: 100% !important;
  overflow-x: hidden;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  font-feature-settings: "case" 1, "rlig" 1, "calt" 0;
  text-rendering: optimizeLegibility;

  * {
    font-family: $font-OS;
    outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .apexcharts-canvas * {
    font-family: $font-OS;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }
}

// *::-webkit-scrollbar {
//   width: 8px;
// }

// /* Track */
// *::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
//   -webkit-border-radius: 10px;
//   border-radius: 10px;
// }

// /* Handle */
// *::-webkit-scrollbar-thumb {
//   -webkit-border-radius: 10px;
//   border-radius: 10px;
//   background: rgb(231, 231, 231);
//   -webkit-box-shadow: inset 0 0 6px rgba(22, 22, 22, 0.3);
// }

// *::-webkit-scrollbar-thumb:window-inactive {
//   background: rgb(221, 221, 221);
// }

// .Content::-webkit-scrollbar {
//   width: 8px;
// }

/* Track */
.Content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.Content::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(175, 175, 175);
  -webkit-box-shadow: inset 0 0 6px rgba(22, 22, 22, 0.5);
}

.Content::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(221, 221, 221);
}

@-ms-viewport {
  width: device-width;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  position: relative;
  min-height: 100%;
  background: #f5f8fa;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

b {
  font-weight: 500;
}

.AppWrapper {
  width: 100%;
  position: relative;
  .App {
    @include css3-prefix("transition", "all 0.3s ease-in");
    width: 100%;
    background-color: $content-background;
    position: relative;
    padding: 60px 0 60px 250px;

    .Content {
      overflow-x: auto;
      overflow-y: auto;
      width: 100%;
      padding: 16px 16px 1vh;
    }
  }
}

.Trial__Alert {
  margin-left: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 16px;
  border: 1px solid #ff8585;
  border-left: 6px solid #ff8585;
  background-color: #fff8f8;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 16px;

  .Trial__Alert__Text {
    font-size: 16px;
    text-align: right;
    padding-right: 8px;
    font-weight: bold;
  }

  .Trial__Alert__Button {
    margin-left: auto;
  }
}

.overflow-hidden {
  overflow-y: auto;
}

.modal-overlay{
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;  
  justify-content: center;
}

@import "../_media-queries/_general";
