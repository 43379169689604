@media all and (max-width: 1024px) {
    .App {
        padding-left: 0px !important;
    }
    .Trial__Alert {
        .Trial__Alert__Text {
            font-size: 14px;
        }
        .Trial__Alert__Sub_Text {
            font-size: 12px;
        }
    }
    .spinner {
        padding-left: 0;
    }
}
