@import "../_media-queries/_overrides";

.ant-modal {
    max-width: 100%;
}

.ant-fullcalendar-calendar-body {
    overflow-x: auto;
    .ant-fullcalendar-table {
        min-width: 930px;
    }
}
