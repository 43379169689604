.customers_statBar {
    .ant-statistic-title {
        position: relative;
        justify-content: center;
        display: flex;
    }

    .ant-statistic-content {
        display: flex;
        justify-content: center;
    }
}

