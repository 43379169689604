$base-font-size: 62.5%;
$font-OS: 'Roboto', system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;

$white: #fff;
$black: #000;

$sidebar-background: #001529;
$sidebar-toggle-background: #506174;
$sidebar-toggle-color: #c7c7c7;

$content-background: #f5f8fa;