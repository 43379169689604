.Pricing__Container {
  .Pricing__Container-subtitle {
    text-align: center;
    font-size: 13px;
    margin-top: 32px;
  }
  .Pricing__Container__blocks {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 32px;

    .Pricing__Container-block {
      min-width: 300px;
      color: #7d7d7d;
      background: white;
      text-align: center;
      padding: 24px 12px 16px 12px;
      border: 1px solid white;
      border-radius: 8px;
      transition: all 0.15s ease-in;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.05), 0 15px 12px rgba(0, 0, 0, 0.06);
      position: relative;

      &:hover {
        transform: scale(1.04);
      }

      .Pricing__Container-block-price {
        font-size: 36px;
        line-height: 36px;
        font-weight: bold;
        margin: 8px 0 0 0;
        color: black;
        &::first-letter {
          font-size: 24px;
        }
      }

      .Pricing__Container-block-desc {
        font-size: 12px;
        margin: 0;
        font-weight: normal;
        color: #7b7b7b;
        margin: 0px 0 16px 0;
      }

      h3 {
        color: black;
        font-weight: bold;
        font-size: 24px;
      }

      Button {
        width: 160px;
        height: 40px;
        padding: 8px 12px;
        margin-bottom: 8px;
      }

      .Pricing__Container__Save {
        position: absolute;
        top: -15px;
        left: calc(50% - 60px);
        height: 30px;
        width: 120px;
        border-radius: 16px;
        background: #31c500;
        border: 1px solid #2fb503;
        color: white;
        text-align: center;
        padding: 4px 8px;
      }

      &.FirstBlock {
        margin-right: 32px;
      }

      &.SecondBlock {
        margin: 0;
      }
    }
  }

  .Features {
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-top: 24px;
      width: 100%;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        top: 16px;
        left: 90px;
        background: #d6d6d6;
        width: calc(100% - 96px);
      }
    }

    .Features__Container__blocks {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .FirstBlock,
      .SecondBlock {
        text-align: left;
        min-width: 300px;
        max-width: 300px;
        margin: 0;
        p {
          margin: 24px 0;
          position: relative;
          padding-left: 36px;
          &::before {
            content: "✓";
            margin-right: 12px;
            color: green;
            border: 2px solid green;
            padding: 8px;
            font-weight: bold;     
            width: 24px;    
            height: 24px;    
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
          }
        }
      }
      .FirstBlock {
        margin-right: 32px;
      }
    }
  }
}

@import "../_media-queries/_purchase";
