//CSS3 PREFIX
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

//BORDER RADIUS
@mixin border-radius($radius) {
  @include css3-prefix('border-radius', $radius);
}

//TEXT TRUNCATE
@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//FONT SIZES FOR BOTH PX'S AND REM'S
@mixin font-size($sizeValue){
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: ($sizeValue/10) + rem;
}

