@media all and (max-width: 800px) {
    .Pricing__Container__blocks {
        flex-direction: column;
        align-items: center !important;
        .FirstBlock {
            margin-right: 0 !important;
            margin-bottom: 36px;
        }
    }

    .Features__Container__blocks {
        flex-direction: column;
        align-items: center !important;     
        .FirstBlock {
            margin-right: 0 !important;
            margin-bottom: 0px;
        }  
        .SecondBlock :first-child {
            margin: 0 !important;
        }  
    }
}
